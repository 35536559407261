import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/routing/router";
import { TOGGLE_LOAD } from '@/store/mutations-types';
// import { dropVueStorage } from '@/utils';

// Map pour stocker les Cancel Tokens par requête
const cancelTokens = new Map();

const axiosRequest = axios.create({
  method: "POST",
  baseURL: process.env.VUE_APP_API_URL,
});

// Intercepteur de requête pour ajouter le Cancel Token et le token d'authentification
axiosRequest.interceptors.request.use((config) => {
  store.commit(TOGGLE_LOAD);

  // Identifier chaque requête de façon unique
  const requestKey = `${config.method}-${config.url}`;
  const source = axios.CancelToken.source();
  config.cancelToken = source.token;

  // Stocker le token dans la Map
  cancelTokens.set(requestKey, source);
  
  // Gérer l'injection du token d'authentification
  if (
    config.url.trim() !== "/user/login" &&
    !config.url.trim().includes("/user/logout") &&
    !config.url.trim().includes("/user/verifyCaptcha") &&
    config.url.trim() !== "/user/forgot-password" &&
    config.url.trim() !== "/user/reset-password" &&
    config.url.trim() !== "/user/rpToken" &&
    config.url.trim() !== "/ged/file/validateLink" &&
    config.url.trim() !== "/user/contactSupport"
  ) {
    // If we are trying to request
    const token = store.state.account.userInfos.token.trim();
    if (token !== "") {
      config.headers.Authorization = `Bearer ${token}`;
      if (config.headers["Content-Type"] === undefined) {
        config.headers["Content-Type"] = "application/json";
      }
    } else {
      Vue.$store.dispatch("account/logout");
      router.push({ name: "Login" });
    }
  }

  return config;
});

//Interceptor API response
axiosRequest.interceptors.response.use(
  (response) => {
    store.commit(TOGGLE_LOAD);
    const requestKey = `${response.config.method}-${response.config.url}`;
    cancelTokens.delete(requestKey); // Supprimer le token après réponse
    return response;
  },
  (error) => {
    store.commit(TOGGLE_LOAD);
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.data.message == "code erroné"
      ) {
        Vue.$toast.error("Code erroné.");
      } else if (error.response.status === 401) {
        if (
          error.response.data.message ===
          "Bad credentials, please verify that your username/password are correctly set"
        ) {
          Vue.$toast.error(
            "Veuillez vérifier que votre adresse email et mot de passe sont correctement définis"
          );
        }


        // store.commit(SET_DISCONNECTED)
        // dropVueStorage()
        store.dispatch('disconnect')

      }
      throw new Error(error.response.data.message);
    } else {
      console.log("LP Intercepteur AuthenticatedRoute: " + error);
    }
  }
);

export const cancelAllRequests = () => {
  cancelTokens.forEach((source) => source.cancel("Navigation en cours."));
  cancelTokens.clear();
};

export default axiosRequest;
