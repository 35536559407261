import axiosRequest from "@/AuthenticatedRoute";

import {
  SET_CONTENT_LOADER_PROJECT,
  SET_DIRECTORY_LOADER_PROJECT,
  ADD_FILES_PROJECT,
  ADD_FILES_SEARCH_PROJECT,
  ADD_FOLDER_PROJECT,
  ADD_FOLDERS_SEARCH_PROJECT,
  ADD_PROJECT,
  ADD_TEAM_PROJECT,
  UPDATE_TEAM_PROJECT,
  RESET_TEAM_PROJECT,
  ADD_EXTERNAL,
  DELETE_FILE_PROJECT,
  DELETE_FOLDER_PROJECT,
  DELETE_PROJECT,
  DELETE_MEMBER_TO_TEAM,
  DROP_LISTENING_MERCURE_FILE_UPDATES_SAFE,
  DROP_LISTENING_MERCURE_FOLDER_UPDATES_SAFE,
  EDIT_FOLDER_PROJECT,
  RESET_CURRENT_FOLDER_PROJECT,
  RESET_CURRENT_MEMBER_TEAM_SLUG,
  RESET_CURRENT_PROJECT_TEAM_SLUG,
  RESET_FOLDER_PROJECT,
  RESET_KEYWORDS_FILES_SEARCH_PROJECT,
  RESET_SEARCHING_FOLDER_PROJECT,
  RESET_SEARCHING_PROJECT,
  SELECT_FILE_PROJECT,
  SELECT_FOLDER_PROJECT,
  SET_CURRENT_FOLDER_PROJECT,
  SET_CURRENT_MEMBER_TEAM_SLUG,
  SET_CURRENT_PROJECT_TEAM_SLUG,
  SET_LISTENING_MERCURE_FILE_UPDATES_SAFE,
  SET_LISTENING_MERCURE_FOLDER_UPDATES_SAFE,
  SET_SEARCHING_FOLDER_PROJECT,
  SET_SEARCHING_PROJECT,
  SHARE_FILE_PROJECT,
  UNSELECT_FILE_PROJECT,
  UNSELECT_FOLDER_PROJECT,
  UNSHARE_FILE_PROJECT,
  UPDATE_FILE_PROJECT,
  UPDATE_PROJECT,
  READ_PROJECT,
  REMOVE_PROJECT,
  REMOVE_TEAM,
} from "@/store/mutations-types";
import {
  buildFormData,
  dateFormatted,
  downloadURI,
  getObjectKey,
  isDefined,
  isEmptyObject,
  stringIsNotBlank,
  capitalize,
} from "@/utils";
import { buildFiles, buildFolders, buildSingleFile } from "./ged";
import {
  FOLDER_PATH_SEPARATOR,
  MERCURE_CREATE_EVENT,
  MERCURE_DELETE_EVENT,
  MERCURE_FILES_TOPIC_PROJECT,
  MERCURE_FOLDERS_TOPIC,
  MERCURE_UPDATE_EVENT,
  PROJECT,
} from "@/constant";

const rootSlug = "root";
const namespaced = true;

const state = {
  //Array of current LPClient Clients
  projects: [],
  projectsLoaded: false,
  project: [],
  teams_project: [],
  folderTree: [],
  isLoadingFolderTree: false,

  current_project_team_slug: "",
  current_member_team_slug: "",

  type: ["Externe", "Interne"],
  status: ["En cours", "Archivé", "Supprimé"],
  // status:['En création','En cours','En attente','Supprimé','Archivé']

  currentFolder: {},

  folders: [],

  selectedFile: {},

  files: [],
  fileStatus: [],
  timeline: [],
  searching: false,

  /**
   * Arrays where we store folder, files and keywords after a search query
   */
  showTagFiles: false,
  selectedTagSearch: "",
  searchingFiles: [],

  searchingFolder: false,
  searchingFolders: [],
  directorySearchContainer: 0,

  searchingKeywords: [],
  keywordFiles: [],

  keywords: [],

  documentRequestHistory: [],

  isSidebarOpen: false,
  isLoadingContent: false,
  isLoadingDirectory: false,
  listeningMercureFileUpdatesSafe: {},
  listeningMercureFolderUpdatesSafe: {},
};

const getters = {
  projectFolders: (state) => {
    return state.folders.filter(
      (folder) => folder.type === PROJECT && folder.parent === rootSlug
    );
  },

  getNameOfProject: (state) => {
    if (state.project.length !== 0) {
      return state.project.name;
    }
  },

  getTeamsNameInProject: (state) => {
    return state.teams_project.map((element) => element.name);
  },

  isUserClientInProject() {
    return (
      state.project.function !== null && state.project.function === "Client"
    );
  },

  getTeamObjOfUserInCurrentProject(state, getters, rootState) {
    var userEmail = rootState.account.userInfos.email;
    var i = 0;
    var teamFound = false;
    var obj = null;
    while (!teamFound && i < state.teams_project.length) {
      var members = state.teams_project[i].members;
      var j = 0;
      while (!teamFound && j < members.length) {
        if (members[j].email === userEmail) {
          teamFound = true;
          obj = state.teams_project[i];
        }
        j += 1;
      }
      i += 1;
    }
    return obj;
  },
  getExternalsObjects(state) {
    var obj = [
      {
        members: [],
        name: "Client",
      },
      {
        members: [],
        name: "Fournisseur",
      },
      {
        members: [],
        name: "Partenaire",
      },
    ];
    var arr = state.project.externals;
    arr.forEach((element) => {
      if (element.function === "Client") {
        obj[0].members.push(element);
      }
      if (element.function === "Fournisseur") {
        obj[1].members.push(element);
      }
      if (element.function === "Partenaire") {
        obj[2].members.push(element);
      }
    });
    return obj;
  },

  isUserResponsibleOfTeam(state, getters, rootState) {
    var currentUserEmail = rootState.account.userInfos.email;
    var i = 0;
    var isResponsilbe = false;
    while (!isResponsilbe && i < state.teams_project.length) {
      if (state.teams_project[i].responsible.email === currentUserEmail) {
        isResponsilbe = true;
      }
      i += 1;
    }
    return isResponsilbe;
  },

  getTeamWhereUserIsResponsible(state, getters, rootState) {
    var currentUserEmail = rootState.account.userInfos.email;
    var i = 0;
    var teams = [];
    while (i < state.teams_project.length) {
      if (state.teams_project[i].responsible.email === currentUserEmail) {
        teams.push(state.teams_project[i]);
      }
      i += 1;
    }
    return teams;
  },

  getTeamWhereUserIsMember(state, getters, rootState) {
    var currentUserEmail = rootState.account.userInfos.email;
    var i = 0;
    var j = 0;
    var teams = [];
    while (i < state.teams_project.length) {
      while (j < state.teams_project[i].members.length) {
        if (state.teams_project[i].members[j].email === currentUserEmail) {
          teams.push(state.teams_project[i]);
        }
        j += 1;
      }
      i += 1;
    }
    return teams;
  },

  isUserExternInProject(state, getters, rootState) {
    var currentUserEmail = rootState.account.userInfos.email;
    var i = 0;
    var isExtern = false;
    while (!isExtern && i < state.project.externals.length) {
      if (state.project.externals[i].email === currentUserEmail) {
        isExtern = true;
      }
      i += 1;
    }
    return isExtern;
  },

  getClientsInProject(state) {
    // var clients = [];
    // state.project.externals.forEach((element) => {
    //   if (element.function === "Client") {
    //     clients.push(element);
    //   }
    // });
    // return clients;
    return [state.project.contact];
  },
};

const mutations = {
  // Mercure
  [SET_LISTENING_MERCURE_FOLDER_UPDATES_SAFE](state, eventSource) {
    state.listeningMercureFolderUpdatesSafe = eventSource;
  },
  [DROP_LISTENING_MERCURE_FOLDER_UPDATES_SAFE](state) {
    state.listeningMercureFolderUpdatesSafe = {};
  },
  [SET_LISTENING_MERCURE_FILE_UPDATES_SAFE](state, eventSource) {
    state.listeningMercureFileUpdatesSafe = eventSource;
  },
  [DROP_LISTENING_MERCURE_FILE_UPDATES_SAFE](state) {
    state.listeningMercureFileUpdatesSafe = {};
  },
  //Project
  [READ_PROJECT](state, { data }) {
    state.project = data;
  },

  [SET_CONTENT_LOADER_PROJECT](state, loader) {
    state.isLoadingContent = loader;
  },

  [SET_DIRECTORY_LOADER_PROJECT](state, loader) {
    state.isLoadingDirectory = loader;
  },

  resetGED(state) {
    (state.currentFolder = {}),
      (state.folders = []),
      (state.selectedFile = {}),
      (state.files = []),
      (state.fileStatus = []),
      (state.timeline = []),
      (state.keywordFiles = []),
      (state.keywords = []),
      (state.documentRequestHistory = []),
      (state.searchingFolders = []),
      (state.searchingFiles = []);
  },
  [REMOVE_PROJECT](state) {
    state.project = [];
  },

  [ADD_PROJECT](state, { projects }) {
    for (const project of projects) {
      if (
        state.projects.filter((item) => item.slug === project.slug).length === 0
      ) {
        state.projects.push(project);
      }
    }

    state.projectsLoaded = true;
  },

  [UPDATE_PROJECT](state, { project }) {
    state.projects = state.projects.map((item) =>
      item.slug === project.slug ? project : item
    );
  },

  [ADD_TEAM_PROJECT](state, { teams }) {
    for (const team of teams) {
      if (
        state.teams_project.filter((item) => item.id === team.id).length === 0
      ) {
        state.teams_project.push(team);
      }
    }
  },

  [DELETE_MEMBER_TO_TEAM](state, data) {
    const project = state.projects.filter(
      (item) => item.slug === data.slugProject
    )[0];
    const team = project.teams.filter((item) => item.id === data.slugTeam)[0];
    const newTeam = team.members.filter(
      (item) => item.email !== data.form.member
    );

    for (let team of state.teams_project) {
      if (team.id === data.slugTeam) {
        team.members = newTeam;
      }
    }

    for (let project of state.projects) {
      if (project.slug === data.slugProject) {
        for (let team of project.teams) {
          if (team.id === data.slugTeam) {
            team.members = newTeam;
          }
        }
      }
    }
  },

  [UPDATE_TEAM_PROJECT](state, { team }) {
    state.teams_project = state.teams_project.map((item) =>
      item.id === team.id ? team : item
    );
  },

  [REMOVE_TEAM](state, team) {
    state.teams_project = state.teams_project.filter(
      (item) => item.id !== team.id
    );
  },

  [RESET_TEAM_PROJECT](state) {
    state.teams_project = [];
  },

  [ADD_EXTERNAL](state, { slug, external, functionExt }) {
    const obj = external;
    obj.function = functionExt;

    state.projects.filter((item) => item.slug === slug)[0].externals.push(obj);
    state.project.externals.push(obj);
  },

  [DELETE_PROJECT](state, { slug }) {
    state.projects = state.projects.filter((item) => item.slug !== slug);
  },

  [SET_CURRENT_PROJECT_TEAM_SLUG](state, slug) {
    state.current_project_team_slug = slug;
  },

  [RESET_CURRENT_PROJECT_TEAM_SLUG](state) {
    state.current_project_team_slug = "";
  },

  [SET_CURRENT_MEMBER_TEAM_SLUG](state, slug) {
    state.current_member_team_slug = slug;
  },

  [RESET_CURRENT_MEMBER_TEAM_SLUG](state) {
    state.current_member_team_slug = "";
  },

  //Status
  // [ADD_PROJECT_STATUS](state, {status}){
  //     for(const elt of status) {
  //         if(state.status.filter(item => item.id === elt.id).length === 0) {
  //             state.status.push(elt)
  //         }
  //     }

  //     state.statusLoaded = true
  // },

  //Type
  // [ADD_PROJECT_TYPE](state, {type}){
  //     for(const elt of type) {
  //         if(state.type.filter(item => item.id === elt.id).length === 0) {
  //             state.type.push(elt)
  //         }
  //     }

  //     state.typeLoaded = true
  // },

  //GED
  [ADD_FOLDER_PROJECT](state, { folders }) {
    for (const folder of folders) {
      if (
        state.folders.filter((item) => item.slug === folder.slug).length === 0
      ) {
        state.folders.push(folder);
      } else {
        state.folders = state.folders.map((item) =>
          item.slug === folder.slug ? folder : item
        );
      }
    }
  },

  [SELECT_FOLDER_PROJECT](state, data) {
    state.folders = state.folders.map((folder) =>
      folder.slug === data.slug ? { ...folder, opened: true } : folder
    );
  },

  [UNSELECT_FOLDER_PROJECT](state, data) {
    state.folders
      .filter((folder) => folder.slug === data.slug)
      .map((folder) => (folder.opened = false));
  },

  [SET_CURRENT_FOLDER_PROJECT](
    state,
    { name, slug, type, depth, opened, fav, parent, arianne }
  ) {
    state.currentFolder = {
      depth,
      name,
      slug,
      type,
      fav,
      parent,
      opened,
      arianne,
    };
  },

  [RESET_CURRENT_FOLDER_PROJECT](state) {
    state.currentFolder = { name: "", slug: "", type: 0, depth: 0 };
  },

  [ADD_FILES_SEARCH_PROJECT](state, { files }) {
    state.searchingFiles = [];

    for (const file of files) {
      if (
        state.searchingFiles.filter((item) => item.slug === file.slug)
          .length === 0
      ) {
        state.searchingFiles.push(file);
      }
    }
  },

  [RESET_KEYWORDS_FILES_SEARCH_PROJECT](state) {
    state.keywordFiles = [];
    state.showTagFiles = false;
  },

  [RESET_SEARCHING_FOLDER_PROJECT](state) {
    state.searchingFolder = false;
    state.searchingFolders = [];
    state.directorySearchContainer = 0;
  },

  [SET_SEARCHING_FOLDER_PROJECT](state, { type }) {
    state.searchingFolder = true;
    state.directorySearchContainer = type;
  },

  [RESET_SEARCHING_PROJECT](state, folderCase) {
    state.searching = false;

    state.searchingFiles = [];
    state.searchingKeywords = [];

    if (folderCase) {
      state.searchingFolder = [];
      state.searchingFolder = false;
    }
  },

  [DELETE_FOLDER_PROJECT](state, { slug }) {
    state.folders = state.folders.filter((dir) => dir.slug !== slug);
  },

  [RESET_FOLDER_PROJECT](state) {
    state.folders = [];
  },

  [EDIT_FOLDER_PROJECT](state, { slug, newName, newArianne }) {
    state.folders
      .filter((folder) => folder.slug === slug)
      .map((folder) => {
        folder.name = newName;
        folder.arianne = newArianne.split(FOLDER_PATH_SEPARATOR);
      });
    state.currentFolder.name = newName;
    state.currentFolder.arianne = newArianne.split(FOLDER_PATH_SEPARATOR);
  },

  [ADD_FOLDERS_SEARCH_PROJECT](state, { folders }) {
    state.searchingFolders = [];

    for (const folder of folders) {
      if (
        state.searchingFolders.filter((item) => item.slug === folder.slug)
          .length === 0
      ) {
        state.searchingFolders.push(folder);
      }
    }
  },

  [ADD_FILES_PROJECT](state, { files }) {
    for (const file of files) {
      if (
        file !== null &&
        state.files.filter((item) => item.slug === file.slug).length === 0
      ) {
        state.files.push(file);
      } else {
        //here we should update file
        state.files = state.files.map((item) =>
          item.slug === file.slug ? file : item
        );
      }
    }
  },

  [DELETE_FILE_PROJECT](state, { slugFile }) {
    state.files = state.files.filter((item) => item.slug !== slugFile);
  },

  [UPDATE_FILE_PROJECT](state, { file }) {
    state.files = state.files.map((item) =>
      item.slug === file.slug ? file : item
    );
  },

  [SELECT_FILE_PROJECT](state, { file }) {
    state.selectedFile = file;
  },

  [UNSELECT_FILE_PROJECT](state) {
    state.selectedFile = { slug: "" };
  },

  [SET_SEARCHING_PROJECT](state) {
    state.searching = true;
  },

  [SHARE_FILE_PROJECT](state, { slugFile, slugFolder }) {
    state.files
      .filter((file) => file.slug === slugFile)
      .map(
        (file) => (file.symbolikParents = [...file.symbolikParents, slugFolder])
      );
  },

  [UNSHARE_FILE_PROJECT](state, { slugFile, slugFolder }) {
    state.files
      .filter((file) => file.slug === slugFile)
      .map(
        (file) =>
          (file.symbolikParents = file.symbolikParents.filter(
            (item) => item !== slugFolder
          ))
      );
  },

  setBillngModel(state, model) {
    state.project.billingModel = model;
  },

  setFolderTree(state, data) {
    state.folderTree = data;
  },

  setLoadingTree(state, value) {
    state.isLoadingFolderTree = value;
  },
};

const actions = {
  removeProject({ commit }) {
    commit(REMOVE_PROJECT);
  },

  resetSearch({ commit }, folderCase = false) {
    if (folderCase) {
      commit(RESET_SEARCHING_PROJECT, folderCase);
    } else {
      commit(RESET_SEARCHING_PROJECT);
    }
  },

  async createProject({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        url: "/project/",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_PROJECT, {
              projects: buildProject(response.data.data, true),
            });
            commit(READ_PROJECT, { data: buildProject(data) });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getProject({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/view/" + id,
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(READ_PROJECT, { data: buildProject(data) });
          }

          if (isDefined(data["teams"])) {
            const teams = data["teams"];
            for (const team of teams) {
              commit(ADD_TEAM_PROJECT, { teams: buildTeamProject(team, true) });
            }
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async updateProject({ commit }, data) {
    const method = "PUT";

    return new Promise((resolve, reject) => {
      axiosRequest({
        method,
        url: "/project/" + data.slug,
        data: buildFormData(data, method, true),
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(UPDATE_PROJECT, {
              project: buildProject(response.data.data),
            });
            commit(READ_PROJECT, { data: buildProject(data) });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async createTeamProject({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        url: "/project/sub/team/",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_TEAM_PROJECT, {
              teams: buildTeamProject(response.data.data, true),
            });
            dispatch("setCurrentProjectTeamSlug", response.data.data.id);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async addTeamMember({ commit }, data) {
    const method = "PUT";

    if (data.form.members.length === 0) {
      return;
    }

    return new Promise((resolve, reject) => {
      axiosRequest({
        method,
        url: "/project/add-member/" + data.slug,
        data: buildFormData(data.form, method, true),
      })
        .then((response) => {
          const data = response.data.data;

          commit(ADD_PROJECT, {
            projects: buildProject(response.data.data, true),
          });
          commit(UPDATE_PROJECT, { project: buildProject(response.data.data) });

          const teams = data["teams"];
          for (const team of teams) {
            commit(UPDATE_TEAM_PROJECT, { team: buildTeamProject(team) });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async removeTeamMember({ commit }, data) {
    const method = "DELETE";

    return new Promise((resolve, reject) => {
      axiosRequest({
        method,
        url: "/project/sub/team/remove-member/" + data.slugTeam,
        data: buildFormData(data.form, method, true),
      })
        .then((response) => {
          commit(DELETE_MEMBER_TO_TEAM, data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async addTeamToProject({ commit }, data) {
    const method = "PUT";

    return new Promise((resolve, reject) => {
      axiosRequest({
        method,
        url: "/project/add-team/" + data.slug,
        data: buildFormData(data.form, method, true),
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_PROJECT, {
              projects: buildProject(response.data.data, true),
            });
            commit(UPDATE_PROJECT, {
              project: buildProject(response.data.data),
            });

            const teams = data["teams"];
            for (const team of teams) {
              commit(UPDATE_TEAM_PROJECT, { team: buildTeamProject(team) });
            }
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async removeTeamToProject({ commit }, data) {
    const method = "PUT";

    return new Promise((resolve, reject) => {
      axiosRequest({
        method,
        url: "/project/remove-team/" + data.slug,
        data: buildFormData(data.form, method, true),
      })
        .then((response) => {
          const responseData = response.data.data;

          if (isDefined(responseData)) {
            commit(UPDATE_PROJECT, {
              project: buildProject(responseData),
            });

            const team = data["team"];
            commit(REMOVE_TEAM, { team: team });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async addExternalMember({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        url: "/project/sub/external/",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;
          const external = data.external;
          external.name = external.firstname + " " + external.lastname;
          commit(ADD_EXTERNAL, {
            slug: data.codeID,
            external: external,
            functionExt: data.function,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async deleteProject({ commit }, { slug }) {
    const method = "DELETE";

    return new Promise((resolve, reject) => {
      axiosRequest({
        method,
        url: "/project/" + slug,
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(DELETE_PROJECT, { slug });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async loadMembersProducedValue({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/member-invoice/producedValue",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async loadAllFolderTree({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingTree", true);
      axiosRequest({
        method: "GET",
        url: `/ged/folder/${slug}/trees`,
      })
        .then((response) => {
          const data = response.data.data;
          commit("setFolderTree", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setLoadingTree", false);
        });
    });
  },

  // eslint-disable-next-line
  async loadMembersProducedTimes({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/member-invoice/producedTimes",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async loadMembersDailyTax({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/member-invoice/dailyTax",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async loadTimeDistribution({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/member-invoice/distribution",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async searchProject({}, {query}) {    
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/search?query=" + query,
      })
        .then((response) => {          
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async loadProjects({}, {page = 1, searchQuery = null, status = 'En cours'}) {      
    let url = searchQuery ? `/project/list?page=${page}&query=${searchQuery}` : `/project/list?page=${page}`;    
    url += `&status=${status}`;

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: url,
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            // const projects = buildProjects(data);

            // commit(ADD_PROJECT, { projects });

            // for(const project of projects){
            //     dispatch('messenger/loadProjectConversations', {slug: project.slug}, {root: true})
            // }
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async loadAllProjects(){
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/list-all",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async loadProjectsPartner({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/sub/external/list-projects/",
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            const projects = buildProjects(data);

            commit(ADD_PROJECT, { projects });

            for (const project of projects) {
              dispatch(
                "messenger/loadProjectConversations",
                { slug: project.slug },
                { root: true }
              );
            }
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async loadCompanyProjects({}, { id }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/company",
        data: buildFormData({ id }),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetCurrentProjectSlug({ commit }) {
    commit(RESET_CURRENT_PROJECT_TEAM_SLUG);
    commit(RESET_CURRENT_MEMBER_TEAM_SLUG);
    commit(RESET_TEAM_PROJECT);
    commit(RESET_FOLDER_PROJECT);
    commit(RESET_CURRENT_FOLDER_PROJECT);
    commit(UNSELECT_FILE_PROJECT);
  },

  setCurrentProjectTeamSlug({ commit }, slug) {
    commit(SET_CURRENT_PROJECT_TEAM_SLUG, slug);
  },

  resetCurrentProjectTeamSlug({ commit }) {
    commit(RESET_CURRENT_PROJECT_TEAM_SLUG);
    commit(RESET_CURRENT_MEMBER_TEAM_SLUG);
  },

  setCurrentMemberTeamSlug({ commit }, slug) {
    commit(SET_CURRENT_MEMBER_TEAM_SLUG, slug);
  },

  resetCurrentMemberTeamSlug({ commit }) {
    commit(RESET_CURRENT_MEMBER_TEAM_SLUG);
  },

  //Project GED
  //Folder
  async loadDirsSafe({ commit, dispatch }, { slug }) {
    commit(SET_DIRECTORY_LOADER_PROJECT, true);
    commit(RESET_FOLDER_PROJECT);
    commit(RESET_CURRENT_FOLDER_PROJECT);
    //commit('resetGED')

    dispatch("listenFileUpdatesSafe"); //Listening to update coming from Mercure server
    dispatch("listenFolderUpdatesSafe"); //Listening to update coming from Mercure server

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/sub/ged/list/" + slug,
      })
        .then((response) => {
          commit(SET_DIRECTORY_LOADER_PROJECT, false);
          const data = response.data.data;

          if (isDefined(data)) {
            const items = getObjectKey(data, "folders");

            if (items !== null) {
              const folders = buildFolders(items);
              commit(ADD_FOLDER_PROJECT, { folders });

              // for (const folder of folders) {
              // dispatch('listFolderFilesSafe', { slugProject: slug, slugFolder: folder.slug })
              //   dispatch("loadSubFoldersSafe", {
              //     slugFolder: folder.slug,
              //     slugProject: slug,
              //     selectFolder: false,
              //   });
              // }
            }
          }

          resolve(response);
        })
        .catch((error) => {
          commit(SET_DIRECTORY_LOADER_PROJECT, false);
          reject(error);
        });
    });
  },
  // eslint-disable-next-line
  async loadDirsOnly({ commit }, { slug }) {
    commit(SET_DIRECTORY_LOADER_PROJECT, true);
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/sub/ged/list/" + slug,
      })
        .then((response) => {
          commit(SET_DIRECTORY_LOADER_PROJECT, false);
          const data = response.data.data;
          if (isDefined(data)) {
            const items = getObjectKey(data, "folders");
            if (items !== null) {
              resolve(buildFolders(items));
            } else {
              resolve([]);
            }
          }
        })
        .catch((error) => {
          commit(SET_DIRECTORY_LOADER_PROJECT, false);
          reject(error);
        });
    });
  },

  loadSubFoldersSafe(
    { commit, state, dispatch },
    { slugProject, slugFolder, selectFolder = true }
  ) {
    const items = state.folders.filter((item) => item.slug === slugFolder);

    if (items.length !== 1) {
      return null;
    }

    let folder = items[0];

    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("slug", slugFolder);

      axiosRequest({
        method: "POST",
        url: "/project/sub/ged/list/" + slugProject,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            dispatch("listFolderFilesSafe", {
              slugProject: slugProject,
              slugFolder: folder.slug,
            });
            if (selectFolder) {
              const item = data.item;
              if (getObjectKey(data, "item")) {
                let folders = buildFolders([item], true);
                folder = folders[0];
                commit(ADD_FOLDER_PROJECT, { folders: folders });

                commit(SELECT_FOLDER_PROJECT, { slug: slugFolder });
                commit(SET_CURRENT_FOLDER_PROJECT, folder);
              }

              commit(ADD_FILES_SEARCH_PROJECT, { files: [] });

              const fldrs = getObjectKey(data, "folders");
              if (fldrs) {
                commit(ADD_FOLDER_PROJECT, { folders: buildFolders(fldrs) });
              }

              const ancestor = getObjectKey(data, "ancestors");
              if (ancestor !== null) {
                commit(ADD_FOLDER_PROJECT, {
                  folders: buildFolders(data.ancestors, true),
                });
              }

              // commit(RESET_KEYWORDS_FILES_SEARCH_PROJECT)
              // commit(RESET_SEARCHING_FOLDER_PROJECT)
              commit(RESET_SEARCHING_PROJECT);
            }
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  loadSubFoldersOnly({}, { slugFolder, slugProject }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("slug", slugFolder);

      axiosRequest({
        method: "POST",
        url: "/project/sub/ged/list/" + slugProject,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            const folders = getObjectKey(data, "folders");
            const ancestor = getObjectKey(data, "ancestors", true);

            if (folders !== null && ancestor !== null) {
              resolve({
                folders: buildFolders(folders) ?? [],
                ancestors: buildFolders(ancestor) ?? [],
              });
            } else {
              resolve([]);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async selectFolderSafe(
    { commit, dispatch, state },
    { slugProject, slugFolder }
  ) {
    let folder = state.folders.filter(
      (folder) => folder.slug === slugFolder
    )[0];

    if (folder === undefined) {
      folder = state.searchingFolders.filter(
        (folder) => folder.slug === slugFolder
      )[0];
      commit(RESET_SEARCHING_PROJECT, true);
    }

    //commit(RESET_SEARCHING_PROJECT,true)

    if (
      folder !==
      undefined /*&& state.folders.filter(folder => folder.parent === slug).length === 0*/
    ) {
      //We load sub - folders from web service only once
      commit(SET_CURRENT_FOLDER_PROJECT, { folder });
      dispatch("loadSubFoldersSafe", {
        slugFolder: folder.slug,
        slugProject: slugProject,
      });
    }
  },

  async unselectFolderSafe({ commit, state }, { slug }) {
    await commit(UNSELECT_FOLDER_PROJECT, { slug });

    if (state.currentFolder.slug === slug) {
      // we should refresh the content vue
      commit(RESET_CURRENT_FOLDER_PROJECT);
    }
  },

  createFolderSafe({ commit, state }, { name, type, slugProject }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();

      if (stringIsNotBlank(name)) {
        formData.append("name", name);
      }

      let folderType;
      if (
        !isEmptyObject(state.currentFolder) && // If there is a selected folder
        parseInt(state.currentFolder.type) === parseInt(type) // If they are from the same view
      ) {
        formData.append("parent", state.currentFolder.slug);
        folderType = state.currentFolder.type;
      } else {
        folderType = type;
      }
      formData.append("type", folderType);

      axiosRequest({
        method: "POST",
        url: "/project/sub/ged/" + slugProject,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_FOLDER_PROJECT, { folders: buildFolders([data]) });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async deleteFolderSafe({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "DELETE",
        url: "/project/sub/ged/" + data.slugProject + "/" + data.slugFolder,
      })
        .then((response) => {
          commit(DELETE_FOLDER_PROJECT, { slug: data.slugFolder });

          commit(RESET_CURRENT_FOLDER_PROJECT);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async editFolderSafe({ commit }, { slugProject, slugFolder, name }) {
    return new Promise((resolve, reject) => {
      const method = "PUT";

      axiosRequest({
        method,
        url: `/project/sub/ged/${slugProject}/${slugFolder}`,
        data: buildFormData({ name }, method, true),
      })
        .then((response) => {
          const result = getObjectKey(response.data, "data");

          if (isDefined(result)) {
            commit(EDIT_FOLDER_PROJECT, {
              slug: getObjectKey(result, "slug", true),
              newName: getObjectKey(result, "name", true),
              newArianne: getObjectKey(result, "arianne", true),
            });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetSearchingFolder({ commit }) {
    commit(RESET_SEARCHING_FOLDER_PROJECT);
  },
  searchFoldersSafe({ commit, state }, { searchQuery, isFromDirectory }) {
    commit(RESET_SEARCHING_FOLDER_PROJECT);
    return new Promise((resolve, reject) => {
      let formData = new FormData();

      formData.append("query", searchQuery);

      if (state.currentFolder.slug) {
        formData.append("slug", state.currentFolder.slug);
        if (isFromDirectory) {
          commit(SET_SEARCHING_FOLDER_PROJECT, { type: 3 });
        }
      }

      axiosRequest({
        method: "POST",
        url: "/project/sub/ged/" + state.project.slug + "/search",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          commit(SET_SEARCHING_PROJECT);
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_FOLDERS_SEARCH_PROJECT, {
              folders: buildFolders(response.data.data),
            });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-empty-pattern
  shareFolderSafe({}, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url:
          "/project/sub/ged/" + data.slugProject + "/share/" + data.slugFolder,
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async listenFolderUpdatesSafe({ rootState, state, commit }) {
    if (!(state.listeningMercureFolderUpdatesSafe instanceof EventSource)) {
      const userEmail = rootState.account.userInfos.email;

      if (stringIsNotBlank(userEmail)) {
        const eventURL =
            process.env.VUE_APP_MERCURE_SERVER_URL +
            MERCURE_FOLDERS_TOPIC +
            "/" +
            userEmail,
          eventSource = new EventSource(eventURL, {
            headers: {
              Authorization:
                "Bearer " + rootState.account.userInfos.mercureToken,
            },
          });

        eventSource.onmessage = (event) => {
          /**
           * This method is called when we received an mercure's update on this chanel
           */
          const serverData = JSON.parse(event.data),
            mercureEvent = getObjectKey(serverData, "event"),
            // data = buildFolder(getObjectKey(serverData, 'data')),
            data = getObjectKey(serverData, "data"),
            slug = getObjectKey(data, "slug"),
            folders = [data];

          switch (mercureEvent) {
            case "delete":
              commit(DELETE_FOLDER_PROJECT, { slug });
              break;
            case "add":
            case "share":
              commit(ADD_FOLDER_PROJECT, { folders });
              break;
            case "edit":
              commit(EDIT_FOLDER_PROJECT, {
                slug: getObjectKey(data, "slug"),
                newName: getObjectKey(data, "name"),
                newArianne: getObjectKey(data, "arianne"),
              });
              break;
            default:
              console.error("this event is not yet managed", mercureEvent);
          }
        };

        eventSource.onerror = (err) => {
          console.error("File ... Something went wrong ", err);
        };
        commit(SET_LISTENING_MERCURE_FOLDER_UPDATES_SAFE, eventSource);
      }
    }
  },
  async stopListenFolderUpdatesSafe({ state, commit }) {
    if (state.listeningMercureFolderUpdatesSafe instanceof EventSource) {
      await state.listeningMercureFolderUpdatesSafe.close();
      commit(DROP_LISTENING_MERCURE_FOLDER_UPDATES_SAFE);
    }
  },

  async listenFileUpdatesSafe({ rootState, commit, dispatch, state }) {
    if (!(state.listeningMercureFileUpdatesSafe instanceof EventSource)) {
      const userEmail = rootState.account.userInfos.email;
      if (stringIsNotBlank(userEmail)) {
        const eventURL =
            process.env.VUE_APP_MERCURE_SERVER_URL +
            MERCURE_FILES_TOPIC_PROJECT +
            "/" +
            userEmail,
          eventSource = new EventSource(eventURL, {
            headers: {
              Authorization:
                "Bearer " + rootState.account.userInfos.mercureToken,
            },
          });

        eventSource.onmessage = async (event) => {
          // Will be called every time an update is published by the server
          const data = JSON.parse(event.data),
            mercureEvent = getObjectKey(data, "event", true),
            slug = getObjectKey(data, "data", true);

          switch (mercureEvent) {
            case MERCURE_CREATE_EVENT:
            case MERCURE_UPDATE_EVENT:
              await dispatch("getFileSafe", {
                slugProject: state.project.slug,
                slug,
              });
              break;
            case MERCURE_DELETE_EVENT:
              commit(DELETE_FILE_PROJECT, { slug });
              break;
            default:
              console.error("this event is not yet managed", mercureEvent);
          }
        };

        eventSource.onerror = (err) => {
          console.error("File ... Something went wrong ", err);
        };
        commit(SET_LISTENING_MERCURE_FILE_UPDATES_SAFE, eventSource);
      }
    }
  },
  async stopListenFileUpdatesSafe({ state, commit }) {
    if (state.listeningMercureFileUpdatesSafe instanceof EventSource) {
      await state.listeningMercureFileUpdatesSafe.close();
      commit(DROP_LISTENING_MERCURE_FILE_UPDATES_SAFE);
    }
  },

  //File
  listFolderFilesSafe({ commit }, { slugProject, slugFolder }) {
    commit(SET_CONTENT_LOADER_PROJECT, true);
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/sub/ged/" + slugProject + "/listFiles/" + slugFolder,
      })
        .then((response) => {
          commit(SET_CONTENT_LOADER_PROJECT, false);
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_FILES_PROJECT, { files: buildFiles(data) });
          }

          resolve(response);
        })
        .catch((error) => {
          commit(SET_CONTENT_LOADER_PROJECT, false);
          reject(error);
        });
    });
  },

  createEmptyFile({ commit, state }, data) {
    if (isEmptyObject(state.currentFolder)) return null;

    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append("title", data.name);
      formData.append("doctype", data.doctype);

      var billingCase = false;
      if (data.billingFolder !== undefined) {
        data.billingCase = true;
        billingCase = true;
        formData.append("type", data.type);
      } else {
        data.billingCase = false;
      }
      var endUrl = billingCase
        ? state.project.billingFolder
        : state.currentFolder.slug;
      axiosRequest({
        method: "POST",
        url:
          "/project/sub/ged/file/" +
          state.project.slug +
          "/createEmptyFile/" +
          endUrl,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_FILES_PROJECT, { files: buildFiles([data]) });
          }

          // dispatch('loadTimeline')

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createFileSafe({ commit, state }, data) {
    if (isEmptyObject(state.currentFolder) && !data.billingFolder) {
      return null;
    }

    return new Promise((resolve, reject) => {
      let formData = new FormData();

      var billingCase = false;
      if (data.billingFolder !== undefined) {
        data.billingCase = true;
        billingCase = true;
        formData.append("type", data.type);
        formData.append("publish", false);
      } else {
        data.billingCase = false;
      }
      var endUrl = billingCase
        ? state.project.billingFolder
        : state.currentFolder.slug;

      data.files.map((file) => {
        formData.append("files[]", file);
      });
      axiosRequest({
        method: "POST",
        url:
          "/project/sub/ged/file/" + state.project.slug + "/create/" + endUrl,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            if (!billingCase) {
              commit(ADD_FILES_PROJECT, { files: buildFiles(data) });
            }
          }

          //dispatch('loadTimeline')

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteFileSafe({ commit }, { slugProject, slugFile }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "DELETE",
        url: "/project/sub/ged/file/" + slugProject + "/" + slugFile,
      })
        .then((response) => {
          commit(DELETE_FILE_PROJECT, { slugFile });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editFileSafe({ commit }, data) {
    return new Promise((resolve, reject) => {
      const method = "PUT";
      axiosRequest({
        method: method,
        url: "/project/sub/ged/file/" + data.slugProject + "/" + data.slugFile,
        data: data,
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            const file = buildSingleFile(data);
            commit(UPDATE_FILE_PROJECT, { file });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getFileSafe({ commit }, { slugProject, slug }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/sub/ged/file/" + slugProject + "/view/" + slug,
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_FILES_PROJECT, { files: buildFiles([data]) });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  selectFileSafe({ commit, state, dispatch }, { slug, selectFolder = false }) {
    const item = state.files.filter((item) => item.slug === slug);

    if (item.length === 1) {
      const file = item[0];

      if (selectFolder) {
        dispatch("selectFolder", { slug: file.folder });
      }
      commit(SELECT_FILE_PROJECT, { file });
    } else {
      dispatch("getFileSafe", { slugProject: state.project.slug, slug });
    }
  },

  unselectFileSafe({ commit }) {
    commit(UNSELECT_FILE_PROJECT);
  },

  // eslint-disable-next-line no-empty-pattern
  downloadFileSafe({}, { slugProject, slugFile, title, type }) {
    return new Promise((resolve, reject) => {
      const fileName = title + "." + type;
      axiosRequest({
        method: "GET",
        url: "/project/sub/ged/file/" + slugProject + "/download/" + slugFile,
        responseType: "blob",
      })
        .then((response) => {
          if (response.status === 200) {
            downloadURI(response.data, fileName);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  searchFileSafe({ commit, state }, { slugProject, searchQuery }) {
    commit(RESET_SEARCHING_PROJECT, true);
    let formData = new FormData();
    formData.append("query", searchQuery);

    // if(isEmptyObject(state.currentFolder)) {
    //     formData.append('type', type)
    // }

    // if (type === 3){
    formData.append("slug", state.currentFolder.slug);
    // }
    commit(SET_SEARCHING_PROJECT);

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/ged/file/" + slugProject + "/search",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_FILES_SEARCH_PROJECT, { files: buildFiles(data) });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // shareFileSafe({commit, state}, {slugProject, slugFile, slugFolder}) {
  //     if(isEmptyObject(state.currentFolder)) {
  //         return null
  //     }
  //
  //     return new Promise((resolve, reject) => {
  //
  //         let formData = new FormData()
  //         formData.append('slugFile', slugFile);
  //         formData.append('slugFolder', slugFolder);
  //
  //         axiosRequest({
  //             url: '/project/sub/ged/file/'+slugProject+'/share',
  //             data: formData,
  //              headers: { "Content-Type": "multipart/form-data" },
  //         }).then(response => {
  //             commit(SHARE_FILE_PROJECT, {slugFile, slugFolder})
  //
  //             resolve(response)
  //         }).catch(error => {
  //             reject(error)
  //         })
  //     })
  // },

  createFolderFromUpload({ commit }, data) {
    commit(ADD_FOLDER_PROJECT, { folders: buildFolders([data]) });
  },
  createFileFromUpload({ commit }, data) {
    commit(ADD_FILES_PROJECT, { files: buildFiles([data]) });
  },

  shareFileToContactSafe(
    { commit, state },
    { slugProject, slugFile, slugFolder, users }
  ) {
    if (isEmptyObject(state.currentFolder)) {
      return null;
    }

    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("slugFile", slugFile);
      formData.append("slugFolder", slugFolder);
      formData.append("users", users);

      axiosRequest({
        url: "/project/sub/ged/file/" + slugProject + "/share",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          commit(SHARE_FILE_PROJECT, { slugFile, slugFolder });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  unShareFileSafe({ commit, state }, { slugProject, slugFile, slugFolder }) {
    if (isEmptyObject(state.currentFolder)) {
      return null;
    }

    return new Promise((resolve, reject) => {
      let formData = new FormData();

      formData.append("slugFile", slugFile);
      formData.append("slugFolder", slugFolder);

      axiosRequest({
        method: "POST",
        url: "/project/sub/ged/file/" + slugProject + "/unShare",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          commit(UNSHARE_FILE_PROJECT, { slugFile, slugFolder });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-empty-pattern
  requestRightsFileSafe({}, { slugProject, slug }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url:
          "/project/sub/ged/file/" + slugProject + "/requestOpenRights/" + slug,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  getMatrices({}, projectId){    
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/getMatrices/" + projectId,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },

  // eslint-disable-next-line
  deleteMatrice({}, {projectId, matriceId}){
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "DELETE",
        url: "/project/deleteMatrice/" + projectId + "/" + matriceId,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },

  // eslint-disable-next-line no-empty-pattern
  premailMerge({}, {id, newFiles, gedFiles}){    
    const formData = new FormData();
    newFiles.map((file) => {
      formData.append("files[]", file);
    });
    formData.append("gedFiles", JSON.stringify(gedFiles));
    
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/preMailMerge/" + id,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-empty-pattern
  mailMerge({}, {id, data}){
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
  
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/mailMerge/" + id,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

function buildProjects(data) {
  let result = [];

  for (let item of data) {
    result.push(buildProject(item));
  }

  return result;
}

function buildProject(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      billingModel: getObjectKey(item, "billingModel"),
      slug: getObjectKey(item, "codeID"),
      name: getObjectKey(item, "name").toUpperCase(),
      description: getObjectKey(item, "description"),
      picture: getObjectKey(item, "picture"),
      isBillable: getObjectKey(item, "isBillable"),
      startDate: dateFormatted(getObjectKey(item, "startDate")),
      endDate: dateFormatted(getObjectKey(item, "endDate")),
      creationStep: getObjectKey(item, "creationStep"),
      teams: getObjectKey(item, "teams"),
      toDoTasks: getObjectKey(item, "toDoTasks"),
      totalEstimation: getObjectKey(item, "totalEstimation"),
      function: getObjectKey(item, "function"),
      showBudgetToClient: getObjectKey(item, "showBudgetToClient"),
      showAmount: getObjectKey(item, "showAmount"),
      showDescription: getObjectKey(item, "showDescription"),
      showLawyerContacts: getObjectKey(item, "showLawyerContacts"),
      billingFolder: getObjectKey(item, "billingFolderSlug"),

      templateFolder: getObjectKey(item, "temlateFolderSlug"),
    };

    if (isDefined(item.packagedBudget)) {
      result["packagedBudget"] =
        getObjectKey(item, "packagedBudget") === true
          ? "accepted"
          : "not_accepted";
    }

    if (isDefined(item.status) && item.status !== null) {
      result["status"] = item.status;
    }

    if (isDefined(item.inCharge) && item.inCharge !== null) {
      result["inCharge"] = {
        id: getObjectKey(item.inCharge, "id"),
        email: getObjectKey(item.inCharge, "email"),
        firstname: capitalize(getObjectKey(item.inCharge, "firstname")),
        lastname: getObjectKey(item.inCharge, "lastname").toUpperCase(),
        avatar: getObjectKey(item.inCharge, "avatar"),
        name:
          capitalize(getObjectKey(item.inCharge, "firstname")) +
          " " +
          getObjectKey(item.inCharge, "lastname").toUpperCase(),
      };
    } else {
      result["inCharge"] = [];
    }

    if (isDefined(item.contact) && item.contact !== null) {
      result["contact"] = {
        id: getObjectKey(item.contact, "id"),
        email: getObjectKey(item.contact, "email"),
        firstname: capitalize(getObjectKey(item.contact, "firstname")),
        lastname: getObjectKey(item.contact, "lastname").toUpperCase(),
        name:
          capitalize(getObjectKey(item.contact, "firstname")) +
          " " +
          getObjectKey(item.contact, "lastname").toUpperCase(),
      };
    } else {
      result["contact"] = [];
    }

    if (isDefined(item.contactOpponent) && item.contactOpponent !== null) {
      result["contactOpponent"] = {
        id: getObjectKey(item.contactOpponent, "id"),
        email: getObjectKey(item.contactOpponent, "email"),
        firstname: capitalize(getObjectKey(item.contactOpponent, "firstname")),
        lastname: getObjectKey(item.contactOpponent, "lastname").toUpperCase(),
        name:
          capitalize(getObjectKey(item.contactOpponent, "firstname")) +
          " " +
          getObjectKey(item.contactOpponent, "lastname").toUpperCase(),
      };
    } else {
      result["contactOpponent"] = [];
    }

    if (isDefined(item.lawyerOpponent) && item.lawyerOpponent !== null) {
      result["lawyerOpponent"] = {
        id: getObjectKey(item.lawyerOpponent, "id"),
        email: getObjectKey(item.lawyerOpponent, "email"),
        firstname: capitalize(getObjectKey(item.lawyerOpponent, "firstname")),
        lastname: getObjectKey(item.lawyerOpponent, "lastname").toUpperCase(),
        name:
          capitalize(getObjectKey(item.lawyerOpponent, "firstname")) +
          " " +
          getObjectKey(item.lawyerOpponent, "lastname").toUpperCase(),
      };
    } else {
      result["lawyerOpponent"] = [];
    }

    if (isDefined(item.company) && item.company !== null) {
      result["company"] = {
        id: getObjectKey(item.company, "id"),
        name: getObjectKey(item.company, "name").toUpperCase(),
        email: getObjectKey(item.company, "email"),
        vat: getObjectKey(item.company, "vat"),
        vat_number: getObjectKey(item.company, "vat_number"),
      };
    } else {
      result["company"] = [];
    }

    if (isDefined(item.companyOpponent) && item.companyOpponent !== null) {
      result["companyOpponent"] = {
        id: getObjectKey(item.companyOpponent, "id"),
        name: getObjectKey(item.companyOpponent, "name").toUpperCase(),
        email: getObjectKey(item.companyOpponent, "email"),
      };
    } else {
      result["companyOpponent"] = [];
    }

    if (isDefined(item.type) && item.type !== null) {
      result["type"] = item.type;
    }

    if (isDefined(item.externals) && item.externals !== null) {
      result["externals"] = [];
      for (const external of item.externals) {
        result["externals"].push({
          id: getObjectKey(external, "id"),
          firstname: capitalize(getObjectKey(external, "firstname")),
          lastname: getObjectKey(external, "lastname").toUpperCase(),
          name:
            capitalize(getObjectKey(external, "firstname")) +
            " " +
            getObjectKey(external, "lastname").toUpperCase(),
          email: getObjectKey(external, "email"),
          function: getObjectKey(external, "function"),
        });
      }
    } else {
      result["externals"] = [];
    }

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}

function buildTeamProject(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      id: parseInt(getObjectKey(item, "id")),
      name: getObjectKey(item, "name"),
      type: 6,
    };

    if (isDefined(item.responsible)) {
      result["responsible"] = {
        id: getObjectKey(item.responsible, "id"),
        firstname: capitalize(getObjectKey(item.responsible, "firstname")),
        lastname: getObjectKey(item.responsible, "lastname").toUpperCase(),
        email: getObjectKey(item.responsible, "email"),
        name:
          capitalize(getObjectKey(item.responsible, "firstname")) +
          " " +
          getObjectKey(item.responsible, "lastname").toUpperCase(),
      };
    } else {
      result["responsible"] = [];
    }

    if (isDefined(item.specialities)) {
      result["specialities"] = {
        id: getObjectKey(item.specialities, "id"),
        name: getObjectKey(item.specialities, "name"),
      };
    } else {
      result["speciality"] = [];
    }

    if (isDefined(item.members)) {
      result["members"] = [];
      for (const element of item.members) {
        result["members"].push({
          id: getObjectKey(element, "id"),
          firstname: capitalize(getObjectKey(element, "firstname")),
          lastname: getObjectKey(element, "lastname").toUpperCase(),
          email: getObjectKey(element, "email"),
          name:
            capitalize(getObjectKey(element, "firstname")) +
            " " +
            getObjectKey(element, "lastname").toUpperCase(),
        });
      }
    } else {
      result["members"] = [];
    }

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}