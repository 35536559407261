/* eslint-disable no-unused-vars */
import {
  AUTHENTIFICATION_IN_PROGRESS,
  SET_CONNECTED,
  SET_PARTNER,
  SET_CLIENT,
  SET_SUBSCRIPTION_PLAN,
  LOGIN,
  UPDATE_CURRENT_USER,
  GENERATE_QR_CODE,
  LIST_LP_CLIENTS,
  GET_COMPANY,
  GET_SELECTED_LPCLIENT,
  LIST_CONTACTS,
  ADD_GLOBAL_NOTIFICATION,
  ADD_SPECIALITY,
  ADD_NEW_NOTIFICATION,
  REMOVE_GLOBAL_NOTIFICATION,
  ADD_USER,
  ADD_COWORKERS,
  DROP_LISTENING_MERCURE_FOR_NEW_NOTIFICATION,
  SET_LISTENING_MERCURE_FOR_NEW_NOTIFICATION,
  SET_TEAM,
  SET_TEAM_MEMBERS,
  ADD_TEAM,
  ADD_TEAM_MEMBER,
  DELETE_TEAM,
  DELETE_TEAM_MEMBER,
  UPDATE_TEAM,
  RESET_STATE,
} from "../mutations-types";
import axiosRequest from "../../AuthenticatedRoute";
import {
  buildFormData,
  dropVueStorage,
  getObjectKey,
  getRandomColor,
  isDefined,
  saveTokenExpiration,
  capitalize,
  stringIsNotBlank,
} from "@/utils";
import {
  avatarColors,
  MERCURE_PROJECT_NOTIFICATION_TOPIC,
  LEGAL_SUCCESS,
  LEGAL_TEAMS,
} from "@/constant";
import { buildGlobalNotification, buildGlobalNotifications } from "../../utils";
import Vue from "vue";
// const axios = require("axios");

const namespaced = true;

const state = {
  userInfos: {
    avatar: null,
    lpClient: null,
    token: null,
  },

  speciality: [],
  specialityLoaded: false,

  listUsers: [],
  listCoworkers: [],
  listLpClients: [],

  listTeams: [],
  teamsLoaded: false,

  selectedLPClient: null,
  company: null,
  listContacts: [],

  globalNotification: [],

  flagLogin: false,
  listeningMercureForNewNotification: {},

  newMembers: [],
};
const getters = {
  getUserFullName(state) {
    return state.userInfos.firstname + " " + state.userInfos.lastname;
  },
  getListUsersForRecommendation(state) {
    var users = state.listUsers.map((element) => {
      element["category"] = "Conseillés";
      element["fullname"] = element.firstname + " " + element.lastname;
      return element;
    });
    var contacts = state.listContacts.map((element) => {
      element["category"] = "Contacts";
      element["fullname"] = element.firstname + " " + element.lastname;
      return element;
    });
    var coworkers = state.listCoworkers.map((element) => {
      element["category"] = "Collaborateurs";
      element["fullname"] = element.firstname + " " + element.lastname;
    });

    return [...users, ...contacts, ...coworkers];
  },
};

const mutations = {
  [LOGIN](
    state,
    { email, name, token, mercureToken, isAdmin, color, isLawyer }
  ) {
    state.userInfos = {
      email,
      name,
      token,
      mercureToken,
      isAdmin,
      color,
      isLawyer,
    };
  },

  [RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },

  updateBio(state, bioObj) {
    state.userInfos.biography = bioObj.biography;
  },
  [SET_LISTENING_MERCURE_FOR_NEW_NOTIFICATION](state, eventSource) {
    state.listeningMercureForNewNotification = eventSource;
  },
  [DROP_LISTENING_MERCURE_FOR_NEW_NOTIFICATION](state) {
    state.listeningMercureForNewNotification = {};
  },
  [ADD_USER](state, { users }) {
    state.listUsers = users;
  },

  [ADD_COWORKERS](state, { users }) {
    for (const user of users) {
      if (
        state.listCoworkers.filter((item) => item.id === user.id).length === 0
      ) {
        if (user.isEmployee) {
          state.listCoworkers.push(user);
        }
      } else {
        state.listCoworkers = state.listCoworkers.map((item) =>
          item.id === user.id ? user : item
        );
      }
    }
  },

  [ADD_SPECIALITY](state, { speciality }) {
    state.speciality = [];
    for (const elt of speciality) {
      if (state.speciality.filter((item) => item.id === elt.id).length === 0) {
        state.speciality.push(elt);
      }
    }

    state.specialityLoaded = true;
  },

  [SET_TEAM](state, { teamIndex, newData }) {
    // state.listTeams[teamIndex] = newData
    Vue.set(state.listTeams, teamIndex, {
      ...state.listTeams[teamIndex],
      ...newData,
    });
  },

  [SET_TEAM_MEMBERS](state, { teamId, members }) {
    const teamIndex = state.listTeams.findIndex((item) => item.id === teamId);
    state.listTeams[teamIndex].members = members;
  },

  fillListTeams(state, { teams }) {
    state.listTeams = teams;
  },

  [ADD_TEAM](state, { team }) {
    const teamIndex = state.listTeams.findIndex((item) => item.id === team.id);

    if (teamIndex !== -1) {
      state.listTeams[teamIndex] = team;
    } else {
      state.listTeams.push(team);
    }

    state.teamsLoaded = true;
  },

  [ADD_TEAM_MEMBER](state, data) {
    let team = state.listTeams[data.teamIndex];
    team.members.push(data.member);
    state.listTeams[data.teamIndex] = team;
  },

  addNewMember(state, { member }) {
    state.newMembers.push(member);
  },

  resetNewMembers(state) {
    state.newMembers = [];
  },

  [DELETE_TEAM](state, index) {
    state.listTeams.splice(index, 1);
  },

  [DELETE_TEAM_MEMBER](state, data) {
    state.listTeams
      .find((item) => item.id === data.teamIndex)
      .members.splice(data.memberIndex, 1);
  },

  deleteNewMemberRow(state, index) {
    state.newMembers.splice(index, 1);
  },

  [UPDATE_TEAM](state, { team }) {
    const index = state.listTeams.findIndex((item) => item.id === team.id);
    if (index !== -1) {
      Vue.set(state.listTeams, index, team);
    }
  },

  [UPDATE_CURRENT_USER](state, data) {
    for (const item in data) {
      state.userInfos[item] = data[item];
    }

    if (
      isDefined(state.userInfos.firstname) &&
      state.userInfos.firstname.trim() !== "" &&
      isDefined(state.userInfos.lastname) &&
      state.userInfos.lastname.trim() !== ""
    ) {
      state.userInfos.initial =
        state.userInfos.firstname[0] + " " + state.userInfos.lastname[0];
      state.userInfos.name =
        state.userInfos.firstname + " " + state.userInfos.lastname;
    } else {
      state.userInfos.initial = "LL";
    }
  },

  [GENERATE_QR_CODE](state, { qrCode, activated }) {
    state.userInfos.qrCode = qrCode;
    state.userInfos.qrCodeActivated = activated;
  },

  [LIST_LP_CLIENTS](state, data) {
    state.listLpClients = data;
  },

  [GET_COMPANY](state, data) {
    state.company = data;
  },

  [GET_SELECTED_LPCLIENT](state, data) {
    state.selectedLPClient = data;
  },

  [LIST_CONTACTS](state, data) {
    state.listContacts = data;
  },

  [ADD_GLOBAL_NOTIFICATION](state, data) {
    for (const globalItem of data) {
      if (
        state.globalNotification.filter((item) => item.id === globalItem.id)
          .length === 0
      ) {
        state.globalNotification.push(globalItem);
      } else {
        // we handle update here
      }
    }
  },
  [ADD_NEW_NOTIFICATION](state, data) {
    if (
      state.globalNotification.filter((item) => item.id === data.id).length ===
      0
    ) {
      state.globalNotification.unshift(data);
    }
  },
  [REMOVE_GLOBAL_NOTIFICATION](state, { id }) {
    state.globalNotification = state.globalNotification.filter(
      (item) => item.id !== id
    );
  },
  updateAvatar(state, avatar) {
    state.userInfos.avatar = avatar;
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },

  signup(commit, accountInfos) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      for (let index in accountInfos) {
        formData.append(index, accountInfos[index]);
      }

      axiosRequest({
        method: "POST",
        url: "/company/create",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          commit(LOGIN, {
            email: response.data.user.email,
            password: accountInfos.password,
          });

          commit(SET_CONNECTED);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  login({ commit, dispatch }, data) {
    const loginData = data;
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/login",
        data: buildFormData(data, "POST", true),
      })
        .then((response) => {
          const data = response.data;

          if (isDefined(data)) {
            commit(UPDATE_CURRENT_USER, {
              token: getObjectKey(data, "token", true),
              color: "#00244D",
              email: loginData.username,
            });
            dispatch("messenger/setSlugCHATBOT", loginData.username, {
              root: true,
            });
            commit(AUTHENTIFICATION_IN_PROGRESS, null, { root: true });
            dispatch("listUsers");
            dispatch("readUser");

            saveTokenExpiration();
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  verifyCaptchaToken({}, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: `/user/verifyCaptcha?token=${data}`,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  twoFactor({ commit }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/2fa",
      })
        .then((response) => {
          const qrCode = response.data.qrCode;
          const activated = response.data.QrCodeActivated;
          commit(GENERATE_QR_CODE, { qrCode, activated });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkTwoFactorCode({ commit }, loginCode) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/verify",
        data: { code: loginCode },
      })
        .then((response) => {
          if (response) {
            const data = response.data;
            commit(SET_CONNECTED, null, { root: true });
            if (data.isPartner) {
              commit(SET_PARTNER, null, { root: true });
            }
            if (data.isClient) {
              commit(SET_CLIENT, null, { root: true });
            }
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setLPClientSubscriptionPlan({ commit }, plan) {
    switch (plan) {
      case "LEGALSUCCESS":
        commit(SET_SUBSCRIPTION_PLAN, LEGAL_SUCCESS, { root: true });
        break;
      case "LEGALTEAMS":
        commit(SET_SUBSCRIPTION_PLAN, LEGAL_TEAMS, { root: true });
        break;
      default:
        commit(SET_SUBSCRIPTION_PLAN, LEGAL_SUCCESS, { root: true });
        break;
    }
  },

  // eslint-disable-next-line
  resetPassword({}, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/reset-password",
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkRpToken({ _ }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/rpToken",
        data: data,
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendForgotPasswordEmail({ _ }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/forgot-password",
        data: data,
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listLPClients({ commit }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/user/getLPClients",
      })
        .then((response) => {
          const data = response.data.LPClients;
          commit(LIST_LP_CLIENTS, buildLPClients(data));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  readSelectedLPClient({ state, commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/user/lp_client/getLPClient/" + id,
        headers: {
          Authorization: "Bearer " + state.userInfos.token,
        },
      })
        .then((response) => {
          const data = response.data;

          if (isDefined(data) && isDefined(data.LPClients)) {
            const lpClient = buildSelectedLPClient(data.LPClients);

            commit(UPDATE_CURRENT_USER, { lpClient: lpClient });

            commit(GET_SELECTED_LPCLIENT, lpClient);
            dispatch("setLPClientSubscriptionPlan", lpClient.offer);
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCompany({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/user/getCompany/" + id,
      })
        .then((response) => {
          const data = response.data.Company;
          commit(GET_COMPANY, buildCompany(data));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  listContacts({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/user/getContacts/" + id,
      })
        .then((response) => {
          const data = response.data.Contacts;

          commit(LIST_CONTACTS, buildContacts(data));

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  listUsers({ commit, state }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/list",
        data: { lp_client: state.selectedLPClient?.id },
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_USER, { users: buildUsers(response.data.data) });
            commit(ADD_COWORKERS, { users: buildUsers(response.data.data) });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  listPartnerUsers({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/user/partner/list/" + id,
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_USER, { users: buildUsers(response.data.data) });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async createSpeciality({ dispatch }, { specialities, lpClientId }) {
    let formData = new FormData();
    formData.append("specialities", specialities);
    formData.append("lpClientId", lpClientId);

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/speciality/",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          dispatch("loadSpecialities");
          resolve(response);
        })
        .catch((error) => {
          console.error("Error creating speciality:", error);
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async updateLPCLientSpecialities({ dispatch }, { specialities, lpClientId }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "PUT",
        url: "/project/sub/speciality/lpClient/" + lpClientId + "/update",
        data: {
          specialities: specialities,
        },
      })
        .then((response) => {
          dispatch("loadSpecialities");
          resolve(response);
        })
        .catch((error) => {
          console.error(
            "Error updating speciality of LP Client id " + lpClientId + " : ",
            error
          );
          reject(error);
        });
    });
  },

  async loadSpecialities({ rootState, commit }) {
    let url = "/project/sub/speciality/lpClient/";
    if (rootState.account.userInfos.role.includes("ROLE_SUPER_ADMIN")) {
      url += rootState.userModule.dataLpclient.id;
    } else {
      url += rootState.account.userInfos.lpClient.id;
    }

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: url,
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_SPECIALITY, {
              speciality: buildSpeciality(data),
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async addOffice({ _ }, { lpclientId, office }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/lp_client/addOffice/" + lpclientId,
        data: office,
      })
        .then((response) => {
          const data = response.data.data;
          resolve(data);
        })
        .catch((error) => {
          console.error("Error adding office", error);
          reject(error);
        });
    });
  },

  async updateOffice({ _ }, { lpclientId, office }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "PUT",
        url: "/user/lp_client/updateOffice/" + lpclientId,
        data: office,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("Error updating office", error);
          reject(error);
        });
    });
  },

  async deleteOffice({ _ }, { lpclientId, officeId }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "DELETE",
        url: "/user/lp_client/deleteOffice/" + lpclientId,
        data: { officeId: officeId },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("Error deleting office", error);
          reject(error);
        });
    });
  },

  /***********************************************/
  /***********TEAMS MANAGEMENT START*************/
  /*********************************************/
  listLpClientTeams({ commit, state }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/sub/team/",
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            state.listTeams = [];
            // for (const team of data) {
            //   commit(ADD_TEAM, { team: buildTeam(team) });
            // }
            commit("fillListTeams", {
              teams: data.map((team) => buildTeam(team)),
            });
            state.teamsLoaded = true;
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addNewTeamRow({ commit }) {
    const now = new Date();
    const team = {
      id: "new_" + now.toISOString().replace(/[-:.TZ]/g, ""),
      name: "Nouvelle équipe",
      specialities: null,
      members: [],
    };
    commit(ADD_TEAM, { team: buildTeam(team) });
  },

  async removeTeamRow({ state, commit }, index) {
    const team = state.listTeams[index];

    if (team.isCreated === false) {
      commit(DELETE_TEAM, index);
      Vue.$toast.success("Équipe supprimée avec succès");
      return;
    }

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "DELETE",
        url: "/project/sub/team/" + team.id,
      })
        .then((response) => {
          commit(DELETE_TEAM, index);
          Vue.$toast.success("Équipe supprimée avec succès");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          Vue.$toast.error("Erreur lors de la suppression de l'équipe");
        });
    });
  },

  async deleteTeam({ dispatch }, team) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "DELETE",
        url: "/project/sub/team/" + team.id,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          Vue.$toast.error("Erreur lors de la suppression de l'équipe");
        });
    });
  },

  deleteUnsavedTeams({ state, commit }) {
    state.listTeams.forEach((team, index) => {
      if (team.isCreated === false) {
        commit(DELETE_TEAM, index);
      }
    });
  },

  async createTeam({ state, commit, dispatch }, index) {
    const team = state.listTeams[index];
    const data = {
      name: team.name,
      lp_client_id: state.userInfos.lpClient.id,
      responsible_id: team.responsible.email,
      specialities: [team.specialities.id],
    };

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/team/",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async (response) => {
          const newData = buildTeam(response.data.data);
          if (isDefined(newData)) {
            commit(SET_TEAM, { teamIndex: index, newData: newData });
            Vue.$toast.success("Équipe créée avec succès!");
            dispatch("addFirstMember", {
              teamIndex: index,
              user: team.responsible,
            });
            resolve();
          }
        })
        .catch((error) => {
          console.error(error);
          if (
            error.message == "a team with the chosen specialty already exists"
          ) {
            Vue.$toast.error(
              "Une équipe avec la spécialité choisie existe déjà"
            );
          } else {
            Vue.$toast.error(
              "Erreur lors de la création de l'équipe. Veuillez réessayer."
            );
          }
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async createNewTeam({}, team) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/team/",
        data: team,
      })
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async updateTeam({ state, commit, dispatch }, index) {
    await dispatch("saveTeamMembers", index);
    const team = state.listTeams[index];

    const body = {
      name: team.name,
      responsible_id: team.responsible.email,
      specialities: [team.specialities.id],
    };
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "PUT",
        url: "/project/sub/team/" + team.id,
        data: buildFormData(body),
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            commit(UPDATE_TEAM, { team: buildTeam(response.data.data) });
            Vue.$toast.success("Équipe mise à jour avec succès");
          }
          resolve(response);
        })
        .catch((error) => {
          if (
            error.message == "a team with the chosen specialty already exists"
          ) {
            Vue.$toast.error(
              "Une équipe avec la spécialité choisie existe déjà"
            );
          } else {
            Vue.$toast.error(
              "Erreur lors de la mise à jour de l'équipe. Veuillez réessayer."
            );
          }
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async updateTeamInformations({}, team) {
    const teamId = team.id;

    delete team.id;

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "PUT",
        url: "/project/sub/team/" + teamId,
        data: buildFormData(team),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async updateTeamMembers({}, team) {
    const method = "PUT";
    const data = {
      team: team.id,
      members: team.members,
    };

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: method,
        url: "/project/sub/team/add-member/",
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addNewMemberRow({ commit }, teamIndex) {
    const member = {
      email: "",
      function: "",
    };
    commit("addNewMember", { member });
  },

  async removeMemberRow({ state, commit }, args) {
    const teamIndex = args.teamIndex;
    const memberIndex = args.memberIndex;

    const member = state.listTeams[teamIndex].members[memberIndex];
    if (!member) {
      Vue.$toast.error("Ce membre n'existe pas");
      return;
    }

    if (member.email === "" || member.id === undefined) {
      commit(DELETE_TEAM_MEMBER, {
        teamIndex: teamIndex,
        memberIndex: memberIndex,
      });
      Vue.$toast.success("Membre supprimé avec succès");
      return;
    }

    const data = {
      method: "DELETE",
      slugTeam: state.listTeams[teamIndex].id,
      form: {
        member: state.listTeams[teamIndex].members[memberIndex].email,
      },
    };

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: data.method,
        url: "/project/sub/team/remove-member/" + data.slugTeam,
        data: buildFormData(data.form, data.method, true),
      })
        .then((response) => {
          commit(DELETE_TEAM_MEMBER, {
            teamIndex: teamIndex,
            memberIndex: memberIndex,
          });
          Vue.$toast.success("Membre supprimé avec succès");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          Vue.$toast.error("Erreur lors de la suppression du membre");
        });
    });
  },

  removeMemberFromTeam({ commit }, args) {
    const teamId = args.teamId;
    const member = args.member;

    if (member.email === "" || member.id === undefined) {
      commit("deleteNewMemberRow", args.memberIndex);
      Vue.$toast.success("Membre supprimé avec succès");
      return;
    }

    const data = {
      method: "DELETE",
      slugTeam: teamId,
      form: {
        member: member.email,
      },
    };

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: data.method,
        url: "/project/sub/team/remove-member/" + data.slugTeam,
        data: buildFormData(data.form, data.method, true),
      })
        .then((response) => {
          commit(DELETE_TEAM_MEMBER, {
            teamIndex: teamId,
            memberIndex: args.memberIndex,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          Vue.$toast.error("Erreur lors de la suppression du membre");
        });
    });
  },

  addFirstMember({ commit, dispatch }, args) {
    const member = {
      email: args.user.email,
      firstname: args.user.firstname,
      lastname: args.user.lastname,
      function: "Associé",
      name: args.user.firstname + " " + args.user.lastname,
    };
    commit(ADD_TEAM_MEMBER, { teamIndex: args.teamIndex, member });
    dispatch("saveTeamMembers", args.teamIndex);
  },

  async saveTeamMembers({ state, commit }, teamIndex) {
    const team = state.listTeams[teamIndex];
    if (team.members.length === 0) {
      return;
    }

    const members = team.members.map((item) => {
      return {
        user: item.email,
        isAdmin: item.email === team.responsible.email ? true : false,
        function: item.function,
      };
    });

    const method = "PUT";
    const data = {
      team: team.id,
      members: members,
    };
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: method,
        url: "/project/sub/team/add-member/",
        data: buildFormData(data, method, true),
      })
        .then((response) => {
          const members = response.data.data;

          if (isDefined(members)) {
            const teamData = {
              members: members,
              teamId: data["team"],
            };
            commit(SET_TEAM_MEMBERS, teamData);
            Vue.$toast.success("Membres de l'équipe mis à jour");
          }

          resolve(response);
        })
        .catch((error) => {
          Vue.$toast.error(
            "Erreur lors de la mise à jour des membres de l'équipe. Veuillez réessayer."
          );
          reject(error);
        });
    });
  },

  /***********************************************/
  /***********TEAMS MANAGEMENT END*************/
  /*********************************************/

  async getAllProducedHours({ commit }, teamId) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/project/sub/member-invoice/allproducedtimes/" + teamId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // async getAllProducedHours({ commit }, teamId) {
  //   return new Promise((resolve, reject) => {
  //     axiosRequest({
  //       method: "GET",
  //       url: "/project/sub/speciality/",
  //     })
  //       .then((response) => {
  //         const data = response.data.data;

  //         if (isDefined(data)) {
  //           commit(ADD_SPECIALITY, {
  //             speciality: buildSpeciality(data),
  //           });
  //         }
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  async getTeamsKPIS({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/project/sub/member-invoice/alltimes/" + data.teamId,
        data: buildFormData(data.form),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          //const data = response.data.data;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  readUser({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/user/read",
        headers: {
          Authorization: "Bearer " + state.userInfos.token,
        },
      })
        .then(async (response) => {
          const data = response.data.data;

          await commit(UPDATE_CURRENT_USER, buildUser(data));
          dispatch("profile/setBioInProfile", state.userInfos.biography, {
            root: true,
          });
          dispatch(
            "setLPClientSubscriptionPlan",
            state.userInfos.lpClient.offer
          );
          dispatch("loadLanguage", state.userInfos.lpClient.language, {
            root: true,
          });
          dispatch("listGlobalNotifications");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // logout({ state, dispatch, commit }) {
  //   return new Promise((resolve, reject) => {
  //     axiosRequest({
  //       method: "POST",
  //       url: "/user/logout/" + state.userInfos.id,
  //     })
  //       .then((response) => {
  //         dropVueStorage();
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  async listGlobalNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/user/notification/listUnRead",
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(ADD_GLOBAL_NOTIFICATION, buildGlobalNotifications(data));
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async readGlobalNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/notification/read",
        data: buildFormData(data),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          commit(REMOVE_GLOBAL_NOTIFICATION, { id: parseInt(data.ids) });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateBioInAcc({ commit }, bio) {
    commit("updateBio", bio);
  },
  async updateLpClient({ rootState }, lpClientData) {
    try {
      const response = await axiosRequest({
        method: "PUT",
        url: "/user/lp_client/" + lpClientData.id,
        headers: {
          Authorization: "Bearer " + rootState.account.userInfos.token,
        },
        data: lpClientData,
      });
      if (response.status === 201) {
        rootState.account.userInfos.lpClient = response.data.data[1];
      }
      return response;
    } catch (error) {
      console.error("Erreur lors de la mise à jour du lpclient :", error);
      throw error;
    }
  },

  async uploadImageUser({ commit }, { avatar }) {
    const method = "PUT";
    const newData = {};
    newData.avatar = avatar;
    return new Promise((resolve, reject) => {
      axiosRequest({
        method,
        url: "/user/update-avatar",
        data: newData,
      })
        .then((response) => {
          const data = response.data.data;

          if (isDefined(data)) {
            commit(UPDATE_CURRENT_USER, { avatar: data.avatar });
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async uploadImageLpClient({ rootState }, { avatar, siret }) {
    const method = "PUT";
    const newData = {};
    newData.avatar = avatar;
    return new Promise((resolve, reject) => {
      axiosRequest({
        method,
        url: "/user/lp_client/update-avatar/" + siret,
        data: newData,
      })
        .then((response) => {
          const data = response.data.data;
          if (response.status === 201) {
            rootState.account.userInfos.lpClient.avatar =
              response.data.data.avatar;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  contactSupport({}, data) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/user/contactSupport",
        data: buildFormData(data, "POST", true),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async listenForNewNotification({ rootState, state, commit, dispatch }) {
    if (!(state.listeningMercureForNewNotification instanceof EventSource)) {
      const userEmail = rootState.account.userInfos.email;

      if (stringIsNotBlank(userEmail)) {
        const eventURL =
            process.env.VUE_APP_MERCURE_SERVER_URL +
            MERCURE_PROJECT_NOTIFICATION_TOPIC +
            "/" +
            userEmail,
          eventSource = new EventSource(eventURL, {
            headers: {
              Authorization:
                "Bearer " + rootState.account.userInfos.mercureToken,
            },
          });

        eventSource.onmessage = async (event) => {
          // Will be called every time an update is published by the server
          const data = JSON.parse(event.data);
          if (data) {
            commit(ADD_NEW_NOTIFICATION, buildGlobalNotification(data.data));
          }
        };

        eventSource.onerror = (err) => {
          console.error("Notification listener lost connection...", err);
        };
        commit(SET_LISTENING_MERCURE_FOR_NEW_NOTIFICATION, eventSource);
      }
    }
  },

  async stopListenForNewNotification({ state, commit }) {
    if (state.listeningMercureForNewNotification instanceof EventSource) {
      await state.listeningMercureForNewNotification.close();
      commit(DROP_LISTENING_MERCURE_FOR_NEW_NOTIFICATION);
    }
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};

function getDefaultState() {
  return {
    userInfos: {
      avatar: null,
      lpClient: null,
      token: null,
    },

    speciality: [],
    specialityLoaded: false,

    listUsers: [],
    listCoworkers: [],
    listLpClients: [],

    listTeams: [],
    teamsLoaded: false,

    selectedLPClient: null,
    company: null,
    listContacts: [],

    globalNotification: [],

    flagLogin: false,
  };
}

function buildUsers(data) {
  let result = [];

  for (const item of data) {
    result.push(buildUser(item));
  }

  return result;
}

function buildUser(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      id: parseInt(getObjectKey(item, "id")),
      firstname: capitalize(getObjectKey(item, "firstname")),
      lastname: getObjectKey(item, "lastname").toUpperCase(),
      name:
        capitalize(getObjectKey(item, "firstname")) +
        " " +
        getObjectKey(item, "lastname").toUpperCase(),
      email: getObjectKey(item, "email"),
      role: getObjectKey(item, "role"),
      isAdmin: getObjectKey(item, "isAdmin"),
      is_lawyer: getObjectKey(item, "isLawyer"),
      isContact: getObjectKey(item, "isContact"),
      isEmployee: getObjectKey(item, "isEmployee"),
      color: getRandomColor(avatarColors),
      speciality: getObjectKey(item, "speciality"),
      avatar: getObjectKey(item, "avatar"),
      officePhone: getObjectKey(item, "officePhone"),
      mobilePhone: getObjectKey(item, "mobilePhone"),
      // address: getObjectKey(item, "address"),
      // additionalAddress: getObjectKey(item, "additionalAddress"),
      // city: getObjectKey(item, "city"),
      // postcode: getObjectKey(item, "postcode"),
      hourlyWage: getObjectKey(item, "hourlyWage"),
      targetHours: getObjectKey(item, "targetHours"),
      billableHours: getObjectKey(item, "billableHours"),
      fonction: getObjectKey(item, "function"),
      languages: getObjectKey(item, "languages"),
      biography: getObjectKey(item, "biography"),
      dblAuth: getObjectKey(item, "dblAuth"),
    };

    // if (getObjectKey(item, "country")) {
    //   result["country"] = getObjectKey(item, "country");
    // } else {
    //   result["country"] = {
    //     id: getObjectKey(item, "country_id"),
    //     label: getObjectKey(item, "country_label"),
    //     iso_code: getObjectKey(item, "country_iso_code"),
    //   };
    // }

    if (isDefined(item.office) && item.company !== null) {
      result["office"] = {
        id: getObjectKey(item.office, "id"),
        isPrincipalAddress: getObjectKey(item.office, "isPrincipalAddress"),
        name: getObjectKey(item.office, "name"),
        address: getObjectKey(item.office, "address"),
        additionalAddress: getObjectKey(item.office, "additionalAddress"),
        city: getObjectKey(item.office, "city"),
        postcode: getObjectKey(item.office, "postcode"),
      };
    } else {
      result["office"] = null;
    }

    if (isDefined(item.lpClient)) {
      result["lpClient"] = item.lpClient;
    }

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}

function buildTeam(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      id: getObjectKey(item, "id"),
      name: getObjectKey(item, "name"),
      type: 6,
    };

    if (isDefined(item.responsible)) {
      result["responsible"] = {
        id: getObjectKey(item.responsible, "id"),
        firstname: capitalize(getObjectKey(item.responsible, "firstname")),
        lastname: getObjectKey(item.responsible, "lastname").toUpperCase(),
        email: getObjectKey(item.responsible, "email"),
        avatar: getObjectKey(item.responsible, "avatar"),
        name:
          capitalize(getObjectKey(item.responsible, "firstname")) +
          " " +
          getObjectKey(item.responsible, "lastname").toUpperCase(),
      };
    } else {
      result["responsible"] = [];
    }

    if (isDefined(item.specialities) && item.specialities.length > 0) {
      result["speciality"] = {
        id: getObjectKey(item.specialities[0], "id"),
        name: getObjectKey(item.specialities[0], "name"),
      };
    } else {
      result["speciality"] = null;
    }

    if (isDefined(item.members)) {
      result["members"] = [];
      for (const element of item.members) {
        result["members"].push({
          id: getObjectKey(element, "id"),
          firstname: capitalize(getObjectKey(element, "firstname")),
          lastname: getObjectKey(element, "lastname").toUpperCase(),
          email: getObjectKey(element, "email"),
          name:
            capitalize(getObjectKey(element, "firstname")) +
            " " +
            getObjectKey(element, "lastname").toUpperCase(),
          function: getObjectKey(element, "function"),
          avatar: getObjectKey(element, "avatar"),
          roles: getObjectKey(element, "generalRoles"),
          generalFonction: getObjectKey(element, "generalFunction"),
        });
      }
    } else {
      result["members"] = [];
    }

    if (`${item.id}`.startsWith("new_")) {
      result["isCreated"] = false;
    } else {
      result["isCreated"] = true;
    }

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}

function buildSelectedLPClient(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      id: parseInt(getObjectKey(item, "id")),
      name: getObjectKey(item, "name").toUpperCase(),
      siret: getObjectKey(item, "siret"),
      avatar: getObjectKey(item, "avatar"),
      offer: getObjectKey(item, "offer"),
    };

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}

function buildLPClients(data) {
  let result = [];

  for (const item of data) {
    result.push(buildLPClient(item));
  }

  return result;
}

function buildLPClient(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      id: parseInt(getObjectKey(item, "id")),
      name: getObjectKey(item, "name"),
      avatar: getObjectKey(item, "avatar"),
    };

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}

function buildCompany(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      id: parseInt(getObjectKey(item, "id")),
      name: getObjectKey(item, "name").toUpperCase(),
      email: getObjectKey(item, "email"),
      address: getObjectKey(item, "address"),
      additional_address: getObjectKey(item, "additional_address"),
      city: getObjectKey(item, "city").toUpperCase(),
      postcode: getObjectKey(item, "postcode"),
      office_phone: getObjectKey(item, "office_phone"),
      fax_number: getObjectKey(item, "fax_number"),
      website: getObjectKey(item, "website"),
      siret: getObjectKey(item, "siret"),
      vat: getObjectKey(item, "vat"),
      nace_code: getObjectKey(item, "nace_code"),
      avatar: getObjectKey(item, "avatar"),
      is_prospect: getObjectKey(item, "is_prospect"),
      is_customer: getObjectKey(item, "is_customer"),
      is_supplier: getObjectKey(item, "is_supplier"),
      is_cabinet: getObjectKey(item, "is_cabinet"),
    };

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}

function buildContacts(data) {
  let result = [];

  for (const item of data) {
    result.push(buildContact(item));
  }

  return result;
}

function buildContact(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      id: parseInt(getObjectKey(item, "id")),
      firstname: capitalize(getObjectKey(item, "firstname")),
      lastname: getObjectKey(item, "lastname").toUpperCase(),
      email: getObjectKey(item, "email"),
      function: getObjectKey(item, "function"),
      city: getObjectKey(item, "city"),
      officePhone: getObjectKey(item, "officePhone"),
    };

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}

function buildSpeciality(data) {
  let result = [];

  for (let item of data) {
    result.push(buildSpecialityItem(item));
  }

  return result;
}

function buildSpecialityItem(item, returnArray = false) {
  let result = null;

  if (isDefined(item)) {
    result = {
      id: parseInt(getObjectKey(item, "id")),
      name: getObjectKey(item, "name"),
      isBasic: getObjectKey(item, "isBasic"),
    };

    if (returnArray) {
      result = [result];
    }
  }

  return result;
}
